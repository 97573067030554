<template>


<div :class=" { 'blur' : show_modal } ">
	


	<Header />

<section>
	
	<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    
  </div>
  <div class="carousel-inner">



    <div class="carousel-item active">
      
	<section style="background-image:url('/images/WhatsApp Image 2024-07-22 at 10.47.15 PM.jpeg'); padding-top: 100px; padding-bottom: 100px; background-position: bottom;  background-size: cover; background-color: rgba(0, 0, 0, 0.6); background-blend-mode: darken; ">
		
		<div class="container">

			<div class="row">



				<div class="col-md-12">


				<div>
						<h3 style="color: #fff;" class="animate__animated animate__zoomIn"><b>START YOUR HEALTHY JOURNEY TODAY</b></h3>
					</div>


					<div>
						<h1 class="animate__animated animate__zoomIn" style="font-size:5vw; font-weight: bold; color: #fff;">Eat Clean Achara</h1>
					</div>


					<div>
						<h3 style="color: #fff;" class="animate__animated animate__zoomIn">Enjoy meals the healthy way</h3>
					</div>



					<div style="padding-top:50px;">
						<router-link style="margin-right: 10px;" to="/all-menu" class="btn btn-2 btn-lg animate__animated animate__zoomIn">Make an order</router-link>
						<button @click="show_bmr=true"  class="btn btn-1 btn-lg animate__animated animate__zoomIn">8 weeks weight loss plan</button>
					</div>
					
				</div>

				
			</div>
			
		</div>


	</section>
    </div>




    <div class="carousel-item">
      
	<section style="background-image:url('/images/WhatsApp Image 2024-07-22 at 10.50.39 PM.jpeg'); padding-top: 100px; padding-bottom: 100px; background-position: center;  background-size: cover; background-color: rgba(0, 0, 0, 0.6); background-blend-mode: darken;">
		
		<div class="container">

			<div class="row">
  
				<div class="col-md-12">


				<div>
						<h3 style="color: #fff;" class="animate__animated animate__zoomIn"><b>START YOUR HEALTHY JOURNEY TODAY</b></h3>
					</div>


					<div>
						<h1 class="animate__animated animate__zoomIn" style="font-size:5vw; font-weight: bold; color: #fff;">Eat Clean Achara</h1>
					</div>


					<div>
						<h3 class="animate__animated animate__zoomIn" style="color: #fff;">Enjoy meals the healthy way</h3>
					</div>



					<div style="padding-top:50px;">
						<router-link style="margin-right: 10px;" to="/all-menu" class="btn btn-2 btn-lg animate__animated animate__zoomIn">Make an order</router-link>
						<button @click="show_bmr=true"  class="btn btn-1 btn-lg animate__animated animate__zoomIn">8 weeks weight loss plan</button>
					</div>
					
				</div>

				
			</div>
			
		</div>


	</section>
    </div>


    
  </div>
  <!--
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>-->

  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</section>


<!--

	<section style="padding-top:50px; padding-bottom: 50px;">
	
	<div class="container">


		<div>
			<h1>HOT <b class="color-1">ON MENU</b></h1>
		</div>





		<div>
  
     <div class="center1" id="content" ref="content">

      <div class="mt-3 internal1" v-for="i in items">

					<div class="shadow">
						
						<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/product-images/'+i.image+')', 'width' : '100%', 'height' : '200px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } "></div>

						<div class="p-1" style="height: 60px; word-break:break-word;   white-space: normal;">
							<h6> {{i.name}} </h6>
						</div>


						<div class="row p-1">

							<div class="col-md-6 col-6">
								<h5 class="color-1"><b>Ksh.{{i.price}}</b> </h5>
							</div>


							<div class="col-md-6 col-6" style="text-align:right;">

								<p style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis;"> <i class="bi bi-check color-1"></i> {{ i.category }} </p>
								
							</div>
							
						</div>


						<div class="row p-1">

							<div class="col-md-6 col-6">
								<router-link :to=" { name : 'product-details', params : { product_number : i.product_number } } " class="btn btn-3 w-100">View</router-link>
							</div>


							<div class="col-md-6 col-6" style="text-align:right;">
								<button @click="add_to_cart(i.product_number,i.name,i.price)" class="btn btn-2 w-100">Add to cart</button>
							</div>
							
						</div>

					</div>
					
				</div>


     </div>



     <div>
      
      <button class="btn next-btn" id="left-button" @click="swipeLeft">
        <i class="bi bi-chevron-left"></i>
      </button>

      <button class="btn next-btn" id="right-button" @click="swipeRight">
         <i class="bi bi-chevron-right"></i>
      </button>

    </div>

</div>


		
	</div>


	</section>

-->




	<section style="background-color: #f5f5f5; padding-top: 100px; padding-bottom: 100px;">
		

		<div style="text-align:center;">
			<h1>
				REAL, HEALTHY FOOD <b class="color-1">THAT ACTUALLY TASTES GOOD.</b>
			</h1>
			<h5>Choose from a wide variety of deliciously clean meals.</h5>



			<div class="container" style="padding-top:80px;">



				<div class="row">

			<div class="col-md-4 mt-3">

				<img src="/images/order-food-online-150x150.png">

				<h3>YOU CHOOSE</h3>
				<h5>Choose your ideal pack size and select from a wide variety of our deliciously clean meals.</h5>
				
			</div>


			<div class="col-md-4 mt-3">

				<img src="/images/we-prepare-food-150x150.png">

				<h3>WE PREPARE</h3>
				<h5>We expertly prepare, weigh, and cook your meals with high-quality ingredients.</h5>
				
			</div>



			<div class="col-md-4 mt-3">

				<img src="/images/enjoy-eat-clean-food-150x150.png">

				<h3>YOU ENJOY</h3>
				<h5>Your premium quality meals are ready to eat in minutes. Just heat and enjoy!</h5>
				
			</div>
			
		</div>


				
			</div>

		</div>


	</section>




	<section style="padding-top:50px; padding-bottom: 50px;">
	
	<div class="container">



		<div class="row" style="padding-top:20px;">

			<div class="col-md-6 mt-3">
	
			<img src="/images/eatclean-3-2.png" class="w-100">
				
			</div>


			<div class="col-md-6 mt-3">
	
			<img src="/images/output-onlinejpgtools.jpeg" class="w-100">
				
			</div>


			
		</div>

		
		
	</div>


	</section>





	<Footer />




</div>



	<div style="position: fixed; top: 0; width: 100%; padding-top: 200px;" v-if="show_modal">

		<div class="container">

			<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">
				<div class="mb-3 shadow-lg rounded p-3" style="background-color:#fff; text-align: center; ">
					
					<p><span class="color-3">You have not selected a package yet</span><br>
				<b>Select a package below</b></p>

				<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" v-for="p in packages"> {{ p.qty }} </button>
				</div>


				<div>
					<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> Close this window </button>
				</div>

				</div>
			</div>
			<div class="col-md-4"></div>
			
		</div>
			
		</div>
		
		
	</div>





	<!-- bmr calc -->

	<div style="position: fixed; top: 0; width: 100%; padding-top: 10px; z-index: 1200;" v-if="show_bmr">

		<div class="container">

			<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">
				<div class="mb-3 shadow-lg rounded p-3 animate__animated animate__zoomIn" style="background-color:#fff;">

					<div class="row">
						<div class="col-md-8 col-10 mt-2"> <h5>8 weeks weight loss plan</h5> </div>
						<div class="col-md-4 col-2" style="text-align:right;">
							<button @click="show_bmr=false" class="btn text-danger btn-lg"> <i class="bi bi-x"></i> </button>
						</div>
					</div>


					<div style="border-top:solid 1px #ddd; padding-top: 10px;">
						<h5>BMR Calculator</h5>
					</div>


					<div>
						
						<form @submit.prevent="calc_bmr()">
							
							<div class="row">

								<div class="col-md-6 col-6 mt-1">
									<label>Weight(Kgs)</label>
									<input  required type="number" min="0" v-model="weight" class="form-control" placeholder="Body weight">
								</div>

								<div class="col-md-6 col-6 mt-1">
									<label>Fat Mass(%)</label>
									<input required type="number" min="0" max="100" v-model="fat_mass" class="form-control" placeholder="Fat mass">
								</div>

								<div class="col-md-6 col-6 mt-1">
									<label>Gender</label>
									<select required class="form-select" v-model="gender">
										<option value="1">Male</option>
										<option value="0">Female</option>
									</select>
								</div>

								<div class="col-md-6 col-6 mt-1">
									<label>Age</label>
									<input required type="number" min="15" max="80" v-model="age" class="form-control" placeholder="Enter age">
								</div>

								<div class="col-md-12 mt-1">
									<label>Activity</label>
									<select required class="form-select" v-model="activity">
										<option value="1.2">Sedentary</option>
										<option value="1.375">Light active</option>
										<option value="1.55">Moderate active</option>
										<option value="1.725">Very active</option>
									</select>
								</div>

								<div class="col-md-12 mt-3" v-if="tdee">
									
									<div style="background-color: #fafafa; padding: 3px; border-left: solid 4px green; padding-left: 10px;">
										<h6 class="text-success"> Your Total Daily Energy Expenditure (TDEE) is {{tdee}}. 
Select meals that don’t exceed {{tdee-300}} in order to start loosing fat </h6>
									</div>

								</div>


								<div class="col-md-12 mt-3" style="text-align:center;">
									<button class="btn btn-1 w-100">Calculate</button>
								
								</div>
						



						<div class="col-md-6 col-6">

							<div style="padding-top:10px;">
										<router-link style="margin-right: 10px;"  to="/all-menu" class="btn btn-2 w-100">Order food</router-link>
								</div>
							
						</div>



						<div class="col-md-6 col-6">

							<div style="padding-top:10px;">
										<router-link style="margin-right: 10px;"  to="/meal-plan" class="btn btn-2 w-100">Get a meal plan</router-link>
								</div>
							
						</div>





								
							</div>

						</form>

					</div>
					

				</div>
			</div>
			<div class="col-md-4"></div>
			
		</div>
			
		</div>
		
		
	</div>







</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css';
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				categories : [],
				items : [],
				packages : [],
				show_modal : false,
				qty : 1,
				weight : '',
				fat_mass : '',
				age : '',
				gender : '',
				bmr : '',
				show_bmr : false,
				tdee : '',
				activity : ''
			}
		},

		methods : {

			calc_bmr(){

			this.bmr = (13.587*this.weight) + (9.613*this.fat_mass) + (198*this.gender) - (3.351*this.age) + 674
			this.tdee = this.bmr * this.activity
			this.$store.state.tdee = this.tdee

			},

			add_to_cart(product_number,name,price){

				if(this.$store.state.is_package_selected!='yes'){

				this.show_modal = true
				
				return
				}

				let item  = { 'product_number' : product_number, 'name' : name, 'price' : price, 'qty' : this.qty }

												
				if( ( parseInt(this.$store.state.final_total_qty) + parseInt(this.qty) ) > parseInt(this.$store.state.selected_package) ){
					Swal.fire({
					  title: "Error",
					  text: "Items in cart exceeds your package size",
					  icon: "error"
					})

					}else{
				this.$store.state.cart = this.$store.state.cart.concat(item)
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))

				this.$store.state.final_total_qty = parseInt(this.$store.state.final_total_qty) + parseInt(this.qty)

				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty)
				
				Swal.fire({
				  title: "Success",
				  text : 'Item added to cart',
				  icon : 'success',
				  showDenyButton: true,
				  showCancelButton: true,
				  confirmButtonText: "View cart",
				  denyButtonText: `Continue shopping`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.$router.push('/cart')
				  } else if (result.isDenied) {
				    //pass
				  }
				})

				
					}
					this.qty = 1

			},


			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 

				Swal.fire({
					  title: "Success",
					  icon: "success",
					  text: "Package selected successfully.You can now add items to cart",
					  showConfirmButton: true,
					 
					})

				this.show_modal = false
			},

				async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 

			},

			async get_meals(){
				const res = await axios.get(this.$store.state.url+'api/homepage-products').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})
				this.items = res
			},

			async get_categories(){
				const res = await axios.get(this.$store.state.url+'api/get-homepage-categories').then(function(response){
					return response.data
				}).catch(function(error){
					//pass
				})
				this.categories = res
			},





			   scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if ( !( (scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) 
      {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();
        
        function scroll(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress 
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        }
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 800);
    },
    swipeRight() {
      const content = this.$refs.content;
      this.scrollTo(content, 300, 800);
    }
    



		},

		created(){
			this.get_categories()
			this.get_meals()
			this.get_packages()
			if(!this.$store.state.final_total_qty){
				this.$store.state.final_total_qty = 0 
			}
		}
	}

</script>



<style scoped>
	
.internal1{
 width: 260px;
 display: inline-block;
 margin: 5px;
}


@media only screen and (max-width:600px){
.internal1{
 width: 80%;
 display: inline-block;
 margin: 5px;
}	
}

.center1{ 
 margin: 1px;
 overflow: hidden;
 white-space: nowrap;
}

.next-btn{
	background-color: #f5f5f5;
	margin-top: 10px;
	margin-right: 10px;
}

.next-btn:hover{
	background-color: #f1f1f1;
}




</style>

