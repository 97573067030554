<template>
	<Header />



	<section style="padding-top:0px; padding-bottom: 100px;">
		

		<div>
			
			<div class="container" style="padding-top:40px;">

				<div class="row">

				<div class="col-md-3"></div>

				<div class="col-md-6">

					<div style="text-align:left;">
				<h3 class="color-3">GET A MEAL PLAN</h3>
			</div>
					
					<form @submit.prevent="open_page()">

					<div>
						<label>Total Daily Energy Expenditure(TDEE)</label>
						<input required type="text" class="form-control" v-model="tdee">
					</div>
						
					<div>
					<label><b>Choose 3 proteins:</b></label> <br />
					<template v-for="p in proteins">
					<input :id="p" class="m-1"  type="checkbox" v-model="selected_proteins" :value="p"> {{p}}	
					</template>
					</div>


					<div class="mt-2">
					<label><b>Choose 3 carbs:</b></label> <br />
					<template v-for="c in carbs">
					<input :id="c" :value="c" class="m-1"  type="checkbox" v-model="selected_carbs"> {{c}}	
					</template>
					
					</div>


					<div class="mt-2">
						<label>Any allergies or ingredients to avoid</label>
						<textarea class="form-control" v-model="allergies"></textarea>
					</div>


					<div class="mt-2">
						<label>Extra notes to the coach</label>
						<textarea class="form-control" v-model="notes"></textarea>
					</div>


					<div class="row">

						<div class="col-md-6 mt-2">
							<label>First name</label>
							<input required type="text" v-model="first_name" class="form-control">
						</div>


						<div class="col-md-6 mt-2">
							<label>Last name</label>
							<input required type="text" v-model="last_name" class="form-control">
						</div>

					

					<div class="col-md-6 mt-2">
							<label>Email</label>
							<input required type="email" v-model="email" class="form-control">
						</div>


						<div class="col-md-6 mt-2">
							<label>Phone</label>
							<input required type="text" v-model="phone" class="form-control">
						</div>






						
					</div>


					<div class="mt-3">
						
						<div style="background-color: #fafafa; border-left:solid 4px green; padding: 6px;">
							<h4>Amount to pay Ksh. {{amount}}</h4>
						</div>

					</div>


					<div class="mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>
					</div>

					</form>

				</div>
					
					
				</div>
				
			</div>

		</div>


	</section>


	<Footer />




<div style="position:absolute; top: 0; right: 0; width: 100%;" v-if="show_page" class="m-1">

	<div class="row">

		<div class="col-md-3"></div>

		<div class="col-md-6">
			
			<div style="background-color: white;" class="rounded shadow-lg p-2">

				<div class="row">

			<div class="col-md-6 col-6">
			<p class="text-danger">order#{{order_number}}</p>
			</div>

			<div class="col-md-6 col-6">
					<div style="text-align:right;">
				<button class="btn text-danger" @click="show_page=false"> <i class="bi bi-x"></i> Close this window</button>
			</div>
			</div>
			
		</div>

				<div v-html="page" style="width:100%;"></div>
				
			</div>

		</div>

		<div class="col-md-3"></div>
		
	</div>
	
</div>


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				tdee : this.$store.state.tdee,
				proteins : ['Beef','Chicken','Fish','Tofu','Beans/Legumes'],
				carbs : ['Rice','Potatoes','Pasta','Ugali'],
				selected_carbs : [],
				selected_proteins : [],
				amount : 5000,
				notes : '',
				allergies : '',
				disabled : false,
				text : 'Submit',
				page : '',
				show_page : false,
				order_number : '',
				first_name : '',
				last_name : '',
				phone : '',
				email : '',
				paid : false,
				message : ''
			}
		},
		methods : {

			async check_payment(){
				const res = await axios.get(this.$store.state.url+'api/check-payment/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){
					this.message = 'Payment  received'
					this.paid = true
				}else{
					this.message = 'Waiting for you to make payment'
					
				}

				if(this.paid){
				this.meal_plan()
				}
			},


			async open_page(){
				this.order_number = Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 567839)

				let link = this.$store.state.url+'api/open-page/'+this.order_number+'/'+this.first_name+'/'+this.last_name+'/'+this.email+'/'+this.phone+'/'+this.amount+'/payment/now'

				const res = await axios.post(link).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.page = res 
				this.show_page = true
				window.scrollTo({top: 0, behavior: 'smooth'});
				setInterval(()=>{
					if(this.paid==false){
					this.check_payment()	
					}else{
						return
					}
					
				},3000)

			},


		async meal_plan(){
			this.text = 'Please wait...'
			this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/meal-plan',{
					tdee : this.tdee,
					proteins : this.selected_proteins,
					carbs : this.selected_carbs,
					notes : this.notes,
					allergies : this.allergies,
					amount : this.amount,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.text = 'Submit'
			this.disabled = false

				if(res=='success'){
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title : 'Thank you!',
					  text: "Your details have been successfully submitted.Your plan will be  sent to your email within 48hrs.",
					  showConfirmButton: true
					})
					this.$router.push('/')
				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: "Something went wrong!",
					  showConfirmButton: true
					})
				}

			},


		}
	}

</script>