<template>
	<Header />



	<section style="padding-top:10px; padding-bottom: 10px;">
		

		<div style="text-align:center;">
			
			<h3 class="color-3"> <img src="/images/fried-rice.png" style="height:30px;"> MENU</h3>
		

			<div class="container" style="padding-top:10px;">


				<div class="row">


					<div class="col-md-12" style="text-align:center;">

				<button @click="all_menu_items()" class="btn btn-outline-danger">All</button>

				<template v-for="c in categories">

					<button  @click="current_category(c.id)"   class="btn btn-outline-success m-2" :class=" { 'btn btn-danger text-white animate__animated animate__fadeInDown' : c.id==category  } " style="text-decoration: none;" v-if="c.name!='All'">
					<i class="bi bi-check" v-if="c.id==category"></i> {{c.name}}						
				</button>
					
				</template>

				
				

			</div>

			<!--
				<div class="col-md-3 mt-3">
				
				<router-link to="/custom-meals" style="text-decoration: none;">
					<div class="categories" style="background-image: url('/images/collage-assortment-dishes-from-different-countries-world-food-snacks_187166-69374.jpeg');">
					<h1><b>CUSTOM MEALS</b></h1>
					</div>	
				</router-link>
				

			</div>-->

			
					
				</div>
				
			</div>

		</div>

	</section>





	<section>
		<div class="container">

	<div style="text-align:center;" v-if="items.length==0">
				<div class="spinner-border text-danger" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
				<p>Please Wait...</p>
			</div>

			<div class="row">

				<div class="col-md-3 mt-3" v-for="i in items">

					<div class="shadow">
						
						<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/product-images/'+i.image+')', 'width' : '100%', 'height' : '200px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } "></div>

						<div class="p-1" style="min-height: 60px;">
							<h6> {{i.name}} </h6>
						</div>


						<div class="row p-1">

							<div class="col-md-6">
								<h5 class="color-1"><b>Ksh.{{i.price}}</b> </h5>
							</div>


							<div class="col-md-6" style="text-align:right;">

								<p> <i class="bi bi-check color-1"></i> {{ i.category }} </p>
								
							</div>
							
						</div>


						<div class="row p-1">

							<div class="col-md-6 col-6">
								<router-link :to=" { name : 'product-details', params : { product_number : i.product_number } } " class="btn btn-3 w-100">View</router-link>
							</div>


							<div class="col-md-6 col-6" style="text-align:right;">
								<button @click="add_to_cart(i.product_number,i.name,i.price)" class="btn btn-2 w-100">Add to cart</button>
							</div>
							
						</div>

					</div>
					
				</div>

				
			</div>
			
		</div>

	</section>











	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css'

	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				categories : [],
				category : '',
				items : [],
				qty : 1
			}
		},
		methods : {

			add_to_cart(product_number,name,price){

				if(this.$store.state.is_package_selected!='yes'){

				Swal.fire({
					  title: "Please note",
					  icon: "info",
					  text: "You must select a package first before adding a meal to cart",
					  showConfirmButton: true,
					 
					})
				return
				}

				let item  = { 'product_number' : product_number, 'name' : name, 'price' : price, 'qty' : this.qty }

												
				if( ( parseInt(this.$store.state.final_total_qty) + parseInt(this.qty) ) > parseInt(this.$store.state.selected_package) ){
					Swal.fire({
					  title: "Error",
					  text: "Items in cart exceeds your package size",
					  icon: "error"
					})

					}else{
				this.$store.state.cart = this.$store.state.cart.concat(item)
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))

				this.$store.state.final_total_qty = parseInt(this.$store.state.final_total_qty) + parseInt(this.qty)

				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty)
				
				Swal.fire({
				  title: "Success",
				  text : 'Item added to cart',
				  icon : 'success',
				  showDenyButton: true,
				  showCancelButton: true,
				  confirmButtonText: "View cart",
				  denyButtonText: `Continue shopping`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.$router.push('/cart')
				  } else if (result.isDenied) {
				    //pass
				  }
				})

				
					}
					this.qty = 1

			},

			current_category(category){
				this.category = category
				this.menu_items()
			},


			async menu_items(){
				this.items = []

				const res = await axios.get(this.$store.state.url+'api/menu-items/'+this.category).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.items = res 
			},

			async all_menu_items(){
				this.items = []

				const res = await axios.get(this.$store.state.url+'api/all-menu-items').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.items = res 
			},

				async product_categories(){

				const res = await axios.get(this.$store.state.url+'api/list-categories').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.categories = res 
			}

		},

		created(){

			if(this.$store.state.is_package_selected!='yes'){
				this.$router.push('/choose-package')
			}else{
			this.product_categories()	
			}

			if(!this.$store.state.final_total_qty){
				this.$store.state.final_total_qty = 0 
			}

			this.all_menu_items()

			
		}
	}

</script>