<template>
	<Header />

	<section style="padding-top:50px; padding-bottom:100px;">
		

		<div class="container">


			<div class="row">

				

				<div class="col-md-5">
				<img :src="this.$store.state.url+'assets/product-images/'+image" style="width:100%;" class="rounded">
				</div>

				<div class="col-md-7" style="padding-top:30px; padding-bottom: 20px;">

				<div class="alert alert-success row" v-if="this.$store.state.cart.length">
					<div class="col-md-6">
						You have [{{this.$store.state.final_total_qty}}] items in cart
					</div>
					<div class="col-md-6" style="text-align: right;">
						<router-link style="margin-right: 10px;" class="btn btn-2" to="/all-menu">Continue shopping</router-link>
						<router-link class="btn btn-2" to="/cart">View cart</router-link>
					</div>
				</div>


					<h5> {{name}} </h5>


				<div>
				
				<h3 class="color-1"> <b> KSH.{{price}}</b> </h3>

				</div>



				<div class="mb-3" v-if="this.$store.state.is_package_selected!='yes' ">
					
					<p><span class="color-3">You have not selected a package yet</span><br>
				<b>Select a package below</b></p>

				<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" v-for="p in packages"> {{ p.qty }} </button>
				</div>

				</div>



				<div class="mb-3" v-if="this.$store.state.is_package_selected=='yes' ">
					
					<b>Selected package : {{this.$store.state.selected_package}}</b> <button @click="clear_package()" class="btn btn-3">Clear selection</button>

				</div>



				<div class="mb-3" v-if="this.$store.state.is_package_selected=='yes' ">

					<button @click="decrement()" class="btn btn-1"> <i class="bi bi-dash"></i> </button>
					{{qty}}
					<button class="btn btn-1" @click="increment()"> <i class="bi bi-plus"></i> </button>

					<div class="mt-3">
						<button @click="add_to_cart()" class="btn btn-2">Add to cart</button>
					</div>

					
			
					
				</div>



				<div>
				
				<div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
	Nutrition facts        
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">

       <div v-if="!nutrition_facts_image" v-html="nutrition"></div>

        <img v-if="nutrition_facts_image" :src="this.$store.state.url+'assets/nutrition-facts-images/'+nutrition_facts_image" style="height: 600px;">

       


      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Ingredients
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        
        <div v-html="ingredients"></div>

        

      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Description
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        
        <div v-html="description"></div>

      </div>
    </div>
  </div>


   <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
        Heating instructions
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        
        <div v-html="heating_instructions"></div>

      </div>
    </div>
  </div>

</div>


				</div>

					
				</div>
				
			</div>
			
		</div>

	</section>


	<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				details : [],
				product_number : '',
				image : '',
				name : '',
				category : '',
				price : 0,
				nutrition : '',
				ingredients : '',
				description : '',
				heating_instructions : '',
				packages : [],
				qty : 1,
				current_qty : 0,
				nutrition_facts_image : ''
			}
		},

		methods : {

			add_to_cart(){
				let item  = { 'product_number' : this.product_number, 'name' : this.name, 'price' : this.price, 'qty' : this.qty }

												
				if( ( parseInt(this.$store.state.final_total_qty) + parseInt(this.qty) ) > parseInt(this.$store.state.selected_package) ){
					Swal.fire({
					  title: "Error",
					  text: "Items in cart exceeds your package size",
					  icon: "error"
					})

					}else{
				this.$store.state.cart = this.$store.state.cart.concat(item)
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))

				this.$store.state.final_total_qty = parseInt(this.$store.state.final_total_qty) + parseInt(this.qty)

				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty)
				

				Swal.fire({
					  title: "Success",
					  text: "Item added to cart",
					  icon: "success"
					})

				
					}
					this.qty = 1

			},	

			decrement(){
				if(this.qty<2){
					//pass
				}else{
					this.qty--
				}
			},

			increment(){
				
				if(this.qty < this.$store.state.selected_package ){
					this.qty++
				}else{

					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Quantity of items exceeds your package size",
					  showConfirmButton: true,
					})
				}
			},

			clear_package(){
				localStorage.setItem('is_package_selected','no')
				localStorage.setItem('selected_package','')
				this.$store.state.is_package_selected = 'no'
				this.$store.state.selected_package = ''
				this.$store.state.final_total_qty = 0
				localStorage.setItem('final_total_qty',0)
				this.$store.state.cart = []
				localStorage.setItem('cart','')
				
			},

			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 
			},

				async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 
			},

			async product_details(){

				const res = await axios.get(this.$store.state.url+'api/product-details/'+this.product_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res 
				res.forEach((data)=>{
					this.image = data.image
					this.name = data.name 
					this.price = data.price
					this.nutrition = data.nutrition
					this.ingredients = data.ingredients
					this.description = data.description
					this.heating_instructions = data.heating_instructions
					this.nutrition_facts_image = data.nutrition_facts_image
				})
			}
		},

		created(){
		//set qty
		if(!this.$store.state.final_total_qty){
				this.$store.state.final_total_qty = 0 
			}

			this.product_number = this.$route.params.product_number
			this.product_details()
			this.get_packages()

			
		}
	}

</script>