<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-6 col-6">
					<h3>New Influencer</h3>
				</div>
				<div class="col-md-6 col-6" style="text-align: right;">
					<router-link class="btn btn-1" to="/influencers"> <i class="bi bi-people"></i> View influencers</router-link>
				</div>
			</div>


			<div>
				
				<form @submit.prevent="new_influencer()" enctype="multipart/form-data">
					<div class="row">
						<div class="col-md-6 mt-3">
							<label>First name</label>
							<input type="text" v-model="first_name" class="form-control" placeholder="Enter first name">
						</div>

						<div class="col-md-6 mt-3">
							<label>Last name</label>
							<input type="text" v-model="last_name" placeholder="Enter last name"  class="form-control">
						</div>


						<div class="col-md-6 mt-3">
							<label>Email</label>
							<input type="email" v-model="email" placeholder="Enter email address"  class="form-control">
						</div>


						<div class="col-md-6 mt-3">
							<label>Phone</label>
							<input type="text" v-model="phone" placeholder="Enter phone number"  class="form-control">
						</div>


						<div class="col-md-6 mt-3">
							<label>Discount(%)</label>
							<input type="number" min="1" max="100" v-model="discount" placeholder="Enter discount"  class="form-control">
						</div>

					

						<div class="col-md-12 mt-3">
						<button class="btn btn-1" :disabled="disabled">{{text}}</button>	
						</div>

					</div>
				</form>

			</div>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				first_name : '',
				last_name : '',
				email : '',
				phone : '',
				discount : 0,
				text : 'Submit',
				disabled : false
			}
		},
		methods : {

			async new_influencer(){
				if(!this.first_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "First name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.last_name){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Last name is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


					if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Email is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


					if(!this.phone){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Phone is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}


				if(!this.discount){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  text: "Discount is required!",
					  showConfirmButton: false,
					  timer: 1500
					})
					return
				}

				

				this.text = 'Please wait...'
				this.disabled = true
				

				const res = await axios.post(this.$store.state.url+'api/new-influencer',{
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					discount : this.discount
					
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				
				Swal.fire({
					  position: "top-center",
					  icon: "info",
					  text: res,
					  showConfirmButton: true
					})

				this.text = 'Submit'
				this.disabled = false

				this.first_name = ''
				this.last_name = ''
				this.email = ''
				this.phone = ''
				this.discount = ''

			}

		}

	}

</script>