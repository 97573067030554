import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'
import NewProductCategory from '../components/NewProductCategory'
import ProductCategories from '../components/ProductCategories'
import NewProduct from '../components/NewProduct'
import ViewProducts from '../components/ViewProducts'
import NewPackage from '../components/NewPackage'
import ViewPackages from '../components/ViewPackages'
import NewLocation from '../components/NewLocation'
import ViewLocations from '../components/ViewLocations'
import NewRider from '../components/NewRider'
import Orders from '../components/Orders'
import ViewOrder from '../components/ViewOrder'
import ViewRiders from '../components/ViewRiders'
import Home from '../components/Home'
import How from '../components/How'
import Contact from '../components/Contact'
import Faq from '../components/Faq'
import MenuItems from '../components/MenuItems'
import ProductDetails from '../components/ProductDetails'
import AllMenu from '../components/AllMenu'
import TrackOrder from '../components/TrackOrder'
import Cart from '../components/Cart'
import Checkout from '../components/Checkout'
import RiderLogin from '../components/RiderLogin'
import RiderDashboard from '../components/RiderDashboard'
import EditProductCategory from '../components/EditProductCategory'
import EditProduct from '../components/EditProduct'
import EditPackage from '../components/EditPackage'
import EditLocation from '../components/EditLocation'
import NotFound from '../components/NotFound'
import NewNutrientCategory from '../components/NewNutrientCategory'
import NutrientCategories from '../components/NutrientCategories'
import NewNutrientProduct from '../components/NewNutrientProduct'
import NutrientProducts from '../components/NutrientProducts'
import NewNutrientValue from '../components/NewNutrientValue'
import NutrientValues from '../components/NutrientValues'
import CustomMeals from '../components/CustomMeals'
import ChoosePackage from '../components/ChoosePackage'
import MealPlan from '../components/MealPlan'
import Influencers from '../components/Influencers'
import NewInfluencer from '../components/NewInfluencer'
import InfluencerOrders from '../components/InfluencerOrders'





const routes = [
  {
    path: '/sudo',
    name: 'login',
    component: Login
  },
   {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

   {
    path: '/new-product-category',
    name: 'new-product-category',
    component: NewProductCategory
  },



   {
    path: '/product-categories',
    name: 'product-categories',
    component: ProductCategories
  },


    {
    path: '/new-product',
    name: 'new-product',
    component: NewProduct
  },


      {
    path: '/view-products',
    name: 'view-products',
    component: ViewProducts
  },



      {
    path: '/new-package',
    name: 'new-package',
    component: NewPackage
  },



      {
    path: '/view-packages',
    name: 'view-packages',
    component: ViewPackages
  },



      {
    path: '/new-location',
    name: 'new-location',
    component: NewLocation
  },


      {
    path: '/view-locations',
    name: 'view-locations',
    component: ViewLocations
  },



      {
    path: '/new-rider',
    name: 'new-rider',
    component: NewRider
  },


     {
    path: '/orders',
    name: 'orders',
    component: Orders
  },



     {
    path: '/view-order/:order_number',
    name: 'view-order',
    component: ViewOrder
  },


     {
    path: '/view-riders',
    name: 'view-riders',
    component: ViewRiders
  },



     {
    path: '/',
    name: 'home',
    component: Home
  },


     {
    path: '/how-it-works',
    name: 'how-it-works',
    component: How
  },


   {
    path: '/contact',
    name: 'contact',
    component: Contact
  },


   {
    path: '/menu-items/:id',
    name: 'menu-items',
    component: MenuItems,
     meta: {
          watchParam: 'id' //
        }
  },


   {
    path: '/product-details/:product_number',
    name: 'product-details',
    component: ProductDetails
  },

    {
    path: '/all-menu',
    name: 'all-menu',
    component: AllMenu
  },


   {
    path: '/faq',
    name: 'faq',
    component: Faq
  },



   {
    path: '/track-order',
    name: 'track-order',
    component: TrackOrder
  },



   {
    path: '/cart',
    name: 'cart',
    component: Cart
  },



   {
    path: '/checkout',
    name: 'checkout',
    component: Checkout
  },



   {
    path: '/riders',
    name: 'riders',
    component: RiderLogin
  },

    {
    path: '/rider-dashboard',
    name: 'rider-dashboard',
    component: RiderDashboard
  },

  {
    path: '/edit-product-category/:id',
    name: 'edit-product-category',
    component: EditProductCategory
  },

  {
    path: '/edit-product/:product_number',
    name: 'edit-product',
    component: EditProduct
  },


  {
    path: '/edit-package/:id',
    name: 'edit-package',
    component: EditPackage
  },

    {
    path: '/edit-location/:id',
    name: 'edit-location',
    component: EditLocation
  },

   {
    path: '/:notFound',
    component: NotFound
  },

   {
    path: '/new-nutrient-category',
    name : 'new-nutrient-category',
    component: NewNutrientCategory
  },


    {
    path: '/nutrient-categories',
    name : 'nutrient-categories',
    component: NutrientCategories
  },

 {
    path: '/new-nutrient-product',
    name : 'new-nutrient-product',
    component: NewNutrientProduct
  },

   {
    path: '/nutrient-products',
    name : 'nutrient-products',
    component: NutrientProducts
  },


   {
    path: '/new-nutrient-value',
    name : 'new-nutrient-value',
    component: NewNutrientValue
  },


     {
    path: '/nutrient-values',
    name : 'nutrient-values',
    component: NutrientValues
  },


     {
    path: '/custom-meals',
    name : 'custom-meals',
    component: CustomMeals
  },


     {
    path: '/choose-package',
    name : 'choose-package',
    component: ChoosePackage
  },


     {
    path: '/meal-plan',
    name : 'meal-plan',
    component: MealPlan
  },


     {
    path: '/influencers',
    name : 'influencers',
    component: Influencers
  },



     {
    path: '/new-influencer',
    name : 'new-influencer',
    component: NewInfluencer
  },


     {
    path: '/influencer-orders/:code',
    name : 'influencer-orders',
    component: InfluencerOrders
  },

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
