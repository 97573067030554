<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 

			<div class="shadow rounded p-3 mt-3 card-design">
			

			<div class="row">
				<div class="col-md-4">
					<h3>Orders</h3>
				</div>
				
			</div>

			<div class="table-responsive">
				
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Order#</th> <th>Total cost</th> <th>Delivery fee</th> <th>Amount to pay</th> <th>Delivery location</th> <th>Payment</th> <th>Status</th> <th> Order/s changed on</th>   <th>Action</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="o in orders">
							<td> {{o.order_number}} </td>
							<td> Ksh.{{o.total_cost}} </td>
							<td> Ksh.{{o.delivery_fee}} </td>
							<td> Ksh.{{o.amount_to_pay}} </td>
							<td> {{o.location_name}} </td>
							<td> <i v-if="o.payment=='completed'" class="bi bi-check color-1"></i>
							<i v-if="o.payment!='completed'" class="bi bi-x text-danger"></i>	
							 {{o.payment}} </td>
							<td> {{o.status}} </td>

								<td> {{o.order_changed_on}} </td>
							
							<td>

							 <div class="dropdown">
  <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Manage
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><router-link :to=" { name : 'view-order', params : { order_number : o.order_number } } " class="dropdown-item">View</router-link></li>
    <li><button @click="delete_order(o.order_number)"  class="dropdown-item">Delete</button></li>
   
  </ul>
</div> 


</td>
						</tr>
					</tbody>
					
				</table>
				

			</div>



			<h5> Total Orders : {{orders.length}} </h5>
			<h5> Total Sales : Ksh.{{total_sales}} </h5>


			</div>

	

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import Swal from 'sweetalert2'
	import axios from 'axios'

	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
				orders : [],
				code : '',
				total_sales : 0
			}
		},
		methods : {



			async delete_order(order_number){

				const res = await axios.post(this.$store.state.url+'api/delete-order',{
					order_number : order_number
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_orders()
			},


			async get_orders(){

				const res = await axios.get(this.$store.state.url+'api/influencer-orders/'+this.code).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.orders = res 

				if(res.length>0){
					res.forEach((data)=>{
						this.total_sales = data.total_cost
					})
				}

			}

		},

		created(){
			 this.code = this.$route.params.code
			this.get_orders()
		}

	}

</script>