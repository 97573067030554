<template>
	<Header />



	<section style="padding-top:50px; padding-bottom: 100px;">

		<div class="container">

			<div class="row mb-3">
				<div class="col-md-6">
					<h6><b>Fill your pack {{this.$store.state.final_total_qty}}/{{this.$store.state.selected_package}} </b></h6>

					<div>
					<button @click="select_package(p.qty,p.id)" class="btn btn-1 package-btn" :class=" { 'btn-2' : this.$store.state.package_id == p.id } " v-for="p in packages"> {{ p.qty }} </button>

					<button @click="clear_package()" class="btn btn-danger"> Clear </button>

				</div>

				
					
				</div>


				<div class="col-md-6 mt-3" style="text-align:right;">
					<router-link to="/all-menu" class="btn btn-1"> Continue shopping </router-link>
				</div>

			</div>


			<div>
				<h3>Cart</h3>
			</div>



			<div class="table-responsive">
				<table class="table table-hover table-striped">

					<thead>
						<tr>
							<th>Product</th> <th>Price</th> <th>Qty</th> <th>Subtotal</th> <th>Action</th>
						</tr>
					</thead>

					<tbody>
						
						<tr v-for="c in this.$store.state.cart">
							<td> {{c.name}} </td>
							<td> Ksh.{{c.price}} </td>
							<td> {{c.qty}} </td>
							<td> Ksh.{{ c.price*c.qty }} </td>
							<td>
								<button @click="delete_item(c)" class="btn btn-danger"> <i class="bi bi-x"></i> </button>
							</td>
						</tr>


						<tr v-for="c in this.$store.state.custom_meal_cart">
							<td>
							 {{c.protein_category_name}} : {{c.protein_name}} - {{c.protein_value}}
							 <br />
							 {{c.carb_category_name}} : {{c.carb_name}} - {{c.carb_value}}
							 <br />
							 {{c.veg_category_name}} : {{c.veg_name}} - {{c.veg_value}}

							  </td>
							<td> Ksh.{{c.total_price}} </td>
							<td> {{c.qty}} </td>
							<td> Ksh.{{ c.total_price*c.qty }} </td>
							<td>
								<button @click="delete_from_custom_meal(c)" class="btn btn-danger"> <i class="bi bi-x"></i> </button>
							</td>
						</tr>




					</tbody>
					
				</table>
				
			</div>


			<div v-if="this.$store.state.cart.length==0 && this.$store.state.custom_meal_cart.length==0 ">
				<p class="text-danger">No items in cart</p>
			</div>



			<div class="row">

				<div class="col-md-6">
					<h5>Total cost : Ksh.{{total_cost}} <br>
					<span class="color-3">Discount : Ksh.{{discount}}</span> <br>
					Amount to pay : Ksh.{{amount_to_pay}} </h5>
					
				</div>

				<div class="col-md-6" style="text-align:right;">

					<button @click="checkout()" class="btn btn-2">Proceed to checkout</button>
					
				</div>
				
			</div>

			
		</div>
		


	</section>


	<Footer />





	<!-- discount -->

	<div style="position: fixed; top: 0; width: 100%; padding-top: 10px; z-index: 900;" v-if="show_discount">

		<div class="container">

			<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">
				<div class="mb-3 shadow-lg rounded p-3 animate__animated animate__zoomIn" style="background-color:#fff;">

					<div class="row">
						<div class="col-md-8 col-10 mt-2"> <h5>Discount code</h5> </div>
						<div class="col-md-4 col-2" style="text-align:right;">
							<button @click="show_discount=false" class="btn text-danger btn-lg"> <i class="bi bi-x"></i> </button>
						</div>
					</div>


					<div style="border-top:solid 1px #ddd; padding-top: 10px;">
						<h5>Do you have a discount code?</h5>
					</div>


					<div>
						
						<form @submit.prevent="calc_discount()">
							
							<div class="row">

								<div class="col-md-12 col-12 mt-1">
									<label>Yes I have a discount code</label>
									<input  required type="text" v-model="discount_code" class="form-control" placeholder="Enter code here">
								</div>



								<div class="col-md-12 mt-3" style="text-align:center;">
									<button :disabled="disabled" type="submit" class="btn btn-1 w-100">{{text}}</button>
								
								</div>
						



						<div class="col-md-12 col-12">

							<div style="padding-top:10px; text-align:center;">
								<label>No I don't have a discount code</label>
										<router-link style="margin-right: 10px;"  to="/checkout" class="btn btn-2 mt-2 w-100">Go to checkout</router-link>
								</div>
							
						</div>


								
							</div>

						</form>

					</div>
					

				</div>
			</div>
			<div class="col-md-4"></div>
			
		</div>
			
		</div>
		
		
	</div>



</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				total_cost : 0,
				discount : 0,
				amount_to_pay : 0,
				packages : [],
				show_discount : false,
				discount_code : '',
				influencer_discount_amount : 0,
				text : 'Get discount',
				disabled : false
			}
		},

		methods : {


			async calc_discount(){

				this.influencer_discount_amount = 0
				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/calc-discount-from-code',{
					discount_code : this.discount_code
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{

						this.influencer_discount_amount = this.total_cost * (data.discount/100)
						
						localStorage.setItem('influencer_discount_code',this.discount_code)
						this.$store.state.influencer_discount_code = this.discount_code

						this.discount = this.discount+this.influencer_discount_amount
						this.amount_to_pay = this.amount_to_pay - Math.floor(this.influencer_discount_amount)

						localStorage.setItem('total_cost',this.total_cost)
						localStorage.setItem('discount',this.discount)
						localStorage.setItem('amount_to_pay',this.amount_to_pay)


						Swal.fire({
					  title: "Success",
					  icon: "success",
					  text: "Your discount amount is - Ksh. "+this.influencer_discount_amount+'. Total amount to pay : Ksh. '+this.amount_to_pay,
					  showConfirmButton: true,
					  
					})

						this.$router.push('/checkout')

					})

				}else{

					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "The discount code you have entered does not exist!",
					  showConfirmButton: true,
					  
					})

				}

				this.text = 'Get discount'
				this.disabled = false

			},




			checkout(){
				if(this.$store.state.selected_package>this.$store.state.final_total_qty){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Number of items in cart is less than package size",
					  showConfirmButton: true,
					  
					})
					return
				}

				if(this.$store.state.final_total_qty>this.$store.state.selected_package){

					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Number of items in cart exceeds package size",
					  showConfirmButton: true,
					 
					})
					return
				}


				localStorage.setItem('total_cost',this.total_cost)
				localStorage.setItem('discount',this.discount)
				localStorage.setItem('amount_to_pay',this.amount_to_pay)


				this.show_discount = true
				
			},

			clear_package(){

				Swal.fire({
				  title: "Are you sure",
				  text : 'Are you sure you want to clear package?',
				  icon : 'question',
				  showDenyButton: true,
				  showCancelButton: false,
				  confirmButtonText: "Yes",
				  denyButtonText: `No`
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) {
				    this.clear_package1()
				  } else if (result.isDenied) {
				    //pass
				  }
				})

			},


			clear_package1(){
				localStorage.clear()
				localStorage.setItem('is_package_selected','no')
				localStorage.setItem('selected_package','')
				this.$store.state.is_package_selected = 'no'
				this.$store.state.selected_package = ''
				this.$store.state.final_total_qty = 0
				localStorage.setItem('final_total_qty',0)
				this.$store.state.cart = []
				this.$store.state.custom_meal_cart = []
				localStorage.setItem('cart','')
				localStorage.setItem('custom_meal_cart','')

				//clear influencer code too
				this.$store.state.influencer_discount_code = ''

				this.$router.push('/all-menu')
				
			},

			async get_packages(){

				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.packages = res 
			},

			delete_item(c){
				let qty = c.qty
				let i = this.$store.state.cart.indexOf(c)
				this.$store.state.cart.splice(i,1)
				this.$store.state.final_total_qty = this.$store.state.final_total_qty - qty
				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty) 
				localStorage.setItem('cart',JSON.stringify(this.$store.state.cart))
				this.total_cost = 0 
				this.get_total_cost()
			},

			delete_from_custom_meal(c){
				let qty = c.qty
				let i = this.$store.state.custom_meal_cart.indexOf(c)
				this.$store.state.custom_meal_cart.splice(i,1)
				this.$store.state.final_total_qty = this.$store.state.final_total_qty - qty
				localStorage.setItem('final_total_qty',this.$store.state.final_total_qty) 
				localStorage.setItem('custom_meal_cart',JSON.stringify(this.$store.state.custom_meal_cart))
				this.total_cost = 0 
				this.get_total_cost()
			},

			get_total_cost(){
				this.$store.state.cart.forEach((data)=>{
					this.total_cost+=parseInt(data.price*data.qty)
				})

				this.$store.state.custom_meal_cart.forEach((data)=>{
					this.total_cost+=parseInt(data.total_price*data.qty)
				})

				this.package_details()
			},


			async package_details(){

				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.package_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.discount = Math.floor( (data.discount/100)*this.total_cost)
					this.amount_to_pay = this.total_cost - this.discount
				})

				 
			},

			select_package(p,id){
				localStorage.setItem('is_package_selected','yes')
				localStorage.setItem('selected_package',p)
				localStorage.setItem('package_id',id)
				this.$store.state.is_package_selected = 'yes'
				this.$store.state.selected_package = p
				this.$store.state.package_id = id 
			},

		},

		created(){

			if(localStorage.getItem('cart')){
			this.$store.state.cart = JSON.parse(localStorage.getItem('cart'))
			this.get_total_cost()
			this.get_packages()	
			}else if(localStorage.getItem('custom_meal_cart')){
			this.$store.state.custom_meal_cart = JSON.parse(localStorage.getItem('custom_meal_cart'))
			this.get_total_cost()
			this.get_packages()	
			}else{
				this.$router.push('/all-menu')
			}

			
			
		}

	}

</script>