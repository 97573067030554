<template>

<div :class=" { 'blur' : show_page } ">
	


		<Header />



	<section style="padding-top:100px; padding-bottom: 100px;">


		<div class="container">

			<div>
				<h3 class="color-3">Checkout</h3>
			</div>


			<form @submit.prevent="place_order()">


			<div class="row">

				<div class="col-md-8">
					
					<h4>Billing details</h4>


					
						
						<div class="row">

							<div class="col-md-6 mt-3">
								<label>First name</label>
								<input type="text" v-model="first_name" placeholder="Enter first name" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Last name</label>
								<input type="text" v-model="last_name" placeholder="Enter last name" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Street address</label>
								<input type="text" v-model="address" placeholder="Enter street address" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Postal code/ZIP code</label>
								<input type="text" v-model="zip" placeholder="Enter postal code/zip code" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Apartment</label>
								<input type="text" v-model="apartment" placeholder="Enter apartment" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Town/City</label>
								<input type="text" v-model="town" placeholder="Enter town/city" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Email</label>
								<input type="email" v-model="email" placeholder="Enter email address" class="form-control">
							</div>

							<div class="col-md-6 mt-3">
								<label>Phone</label>
								<input type="text" v-model="phone" placeholder="Enter phone number" class="form-control">
							</div>

							<div class="col-md-12 mt-3">
								<label>Comments</label>
								<textarea rows="4" class="form-control" v-model="comments" placeholder="Any comments? eg. Allergies,preparation etc"></textarea>

							</div>
							
						</div>

					

				</div>



				<div class="col-md-4">

					<div class="shadow card-design p-2 rounded">

						<h5>Delivery location</h5>

						<div class="mb-3">
							<label>Choose your delivery location below</label>
							<select class="form-select" v-model="location_name" @change="get_delivery_fee()">
								<option  v-for="l in locations"> {{l.name}} </option>
							</select>

							<input v-if="location_name=='Other'" type="text" v-model="other" placeholder="Please specify location" class="form-control mt-3">

						</div>

						<h5>Delivery fee : Ksh. {{delivery_fee}} </h5>
						<h5>Total cost : Ksh. {{amount_to_pay}} </h5>
						<h5>Amount to pay(with delivery) : Ksh. {{final_amount}} </h5>



						<div>
							
							<label>Choose day of delivery</label>
							<select class="form-select" v-model="delivery_day">
								<option>Same day delivery(12:00pm - 5:00pm)</option>
								<option>Next day delivery(08:00am - 12:00pm)</option>
								<option>Next day delivery(12:00pm - 5:00pm)</option>
							</select>

							<label>Please indicate preferred delivery time(within the chosen time)</label><br />
							<label><b>Please note that earliest delivery time is 1.30min  after the order has been made.</b></label>



							<select v-if="delivery_day=='Next day delivery(08:00am - 12:00pm)'" class="form-select" v-model="delivery_time">
								<option>08:00am - 08:30am</option>
								<option>08:30am - 09:00am </option>
								<option>09:00am - 09:30am</option>
								<option>09:30am - 10:00am</option>
								<option>10:00am - 10:30am</option>
								<option>10:30am - 11:00am</option>
								<option>11:00am - 11:30am</option>
								<option>11:30am - 12:00pm </option>
								
							</select>

							<select v-if="delivery_day=='Same day delivery(12:00pm - 5:00pm)' || delivery_day=='Next day delivery(12:00pm - 5:00pm)' " class="form-select" v-model="delivery_time">
								<option>12:00pm - 12:30pm</option>
								<option>12:30pm - 01:00pm</option>
								<option>01:00pm - 01:30pm</option>
								<option>01:30pm - 02:00pm</option>
								<option>02:00pm - 02:30pm</option>
								<option>02:30pm - 03:00pm</option>
								<option>03:00pm - 03:30pm</option>
								<option>03:30pm - 04:00pm</option>
								<option>04:00pm - 04:30pm</option>
								<option>04:30pm - 05:00pm</option>
								
							</select>

						</div>
						
					</div>


					<div class="shadow card-design p-2 rounded mt-3">
						<h5>Payment options</h5>
						<h5> <i class="bi bi-check-circle color-1"></i> <span class="color-3">MPESA/CARD PAYMENT</span> </h5>
						<img src="/images/pesapal-share.png" style="height: 40px;">


						<div class="mt-3">
							<p class="text-danger" v-if="message"> {{message}} <br />
						Do not close this page.It will close automatically</p>
							<button :disabled="disabled"  class="btn btn-1"> {{text}} </button>
							
						
						</div>
						
					</div>
					
				</div>
				
			</div>


		</form>

			
		</div>
		
	</section>


	<Footer />




</div>



<div style="position:absolute; top: 0; right: 0; width: 100%;" v-if="show_page" class="m-1">

	<div class="row">

		<div class="col-md-3"></div>

		<div class="col-md-6">
			
			<div style="background-color: white;" class="rounded shadow-lg p-2">

				<div class="row">

			<div class="col-md-6 col-6">
			<p class="text-danger">order#{{order_number}}</p>
			</div>

			<div class="col-md-6 col-6">
					<div style="text-align:right;">
				<button class="btn text-danger" @click="show_page=false"> <i class="bi bi-x"></i> Close this window</button>
			</div>
			</div>
			
		</div>

				<div v-html="page" style="width:100%;"></div>
				
			</div>

		</div>

		<div class="col-md-3"></div>
		
	</div>
	
</div>




</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : { Header,Footer },
		data(){
			return{
				discount : '',
				total_cost : '',
				amount_to_pay : '',
				locations : [],
				delivery_fee : 0,
				final_amount : 0,
				location_name : '',
				first_name : '',
				last_name : '',
				address : '',
				zip : '',
				apartment : '',
				town : '',
				email : '',
				phone : '',
				text : 'Place order',
				disabled : false,
				other : '',
				delivery_day : '',
				delivery_time : '',
				message : '',
				order_number : '',
				paid : false,
				page : '',
				show_page : false,
				order_number : '',
				comments : '',

				

			}
		},

		methods : {

			async open_page(){
				this.order_number = Math.floor(Math.random() * 10000) + Math.floor(Math.random() * 567839)

				let link = this.$store.state.url+'api/open-page/'+this.order_number+'/'+this.first_name+'/'+this.last_name+'/'+this.email+'/'+this.phone+'/'+this.final_amount+'/payment/now'

				const res = await axios.post(link).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.page = res 
				this.show_page = true
				window.scrollTo({top: 0, behavior: 'smooth'});
				setInterval(()=>{
					if(this.paid==false){
					this.check_payment()	
					}else{
						return
					}
					
				},3000)

			},

			payment_window(link){
				window.open(link,'_blank')

				this.message = 'Waiting for you to make payment...'

				setInterval(()=>{
					if(this.paid==false){
					this.check_payment()	
					}else{
						return
					}
					
				},3000)

			},

			async check_payment(){
				const res = await axios.get(this.$store.state.url+'api/check-payment/'+this.order_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){
					this.message = 'Payment  received'
					this.paid = true
				}else{
					this.message = 'Waiting for you to make payment'
					
				}

				if(this.paid){
					this.place_order_now()
				}
			},


			async place_order_now(){

				

				const res = await axios.post(this.$store.state.url+'api/place-order',{

					first_name : this.first_name,
					last_name : this.last_name,
					phone : this.phone,
					email : this.email,
					address : this.address,
					zip : this.zip,
					apartment : this.apartment,
					town : this.town,
					cart : this.$store.state.cart,
					custom_meal_cart : this.$store.state.custom_meal_cart,
					order_number : this.order_number,
					location_name : this.location_name,
					delivery_fee : this.delivery_fee,
					total_cost : this.amount_to_pay,
					amount_to_pay : this.final_amount,
					delivery_day : this.delivery_day,
					delivery_time : this.delivery_time,
					comments : this.comments,
					influencer_discount_code : this.$store.state.influencer_discount_code

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				//clear influencer code 
				this.$store.state.influencer_discount_code = ''

				localStorage.clear()
				window.location.href='https://eatcleanachara.com/'

	
			},

		
			async place_order(){

				if(!this.first_name){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "First name is required",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.last_name){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Last name is required",
					  showConfirmButton: true,
					 
					})
					return
				}

				if(!this.town){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Town is required",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.address){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Address is required",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.email){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Email is required",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.phone){
					Swal.fire({
					  title: "Error",
					  icon: "error",
					  text: "Phone is required",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.delivery_fee){
					Swal.fire({
					  title: "Note",
					  icon: "info",
					  text: "Please select your delivery location",
					  showConfirmButton: true,
					 
					})
					return
				}


					if(!this.delivery_day){
					Swal.fire({
					  title: "Note",
					  icon: "info",
					  text: "Please select your day of delivery",
					  showConfirmButton: true,
					 
					})
					return
				}


				if(!this.delivery_time){
					Swal.fire({
					  title: "Note",
					  icon: "info",
					  text: "Please select your time of delivery",
					  showConfirmButton: true,
					 
					})
					return
				}

				 

				

				if(this.other){
					this.location_name = this.other
				}

				this.message = "Waiting for you to make payment"
				this.open_page()

			

			},

			get_delivery_fee(){
				this.locations.forEach((data)=>{
					if(data.name==this.location_name){
						this.delivery_fee = data.price
					}
				})

				this.final_amount = parseInt(this.amount_to_pay) + parseInt(this.delivery_fee)

			},

			async get_locations(){

				const res = await axios.get(this.$store.state.url+'api/get-locations').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.locations = res 

			}

		},
		created(){
			this.discount = localStorage.getItem('discount')
			this.total_cost = localStorage.getItem('total_cost')
			this.amount_to_pay = localStorage.getItem('amount_to_pay')
			this.get_locations()
			this.final_amount = parseInt(this.amount_to_pay) + parseInt(this.delivery_fee)
		}
	}

</script>